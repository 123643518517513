import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const DashboardProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Build a Future Proof Organization with{' '}
        <span className="heading-red">Gen AI and AWS</span>
      </>
    ),
    bannerContent: 'Agile in mind, spirit, and speed',
    BannerAlt: 'Simform Banner',
    buttonName: 'Start your AI journey',
    buttonLink: '/contact/',
  },
  homeServiceProps: {
    homeServiceHeading: (
      <>
        Discover what’s next for your{' '}
        <span className="highlight-head">organization with Simform</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Gen AI and ML',
        serviceParagraph:
          'Create generative solutions that automate customer service, optimize processes, and facilitate data-driven decision-making',
        serviceIcon: images.gaiMicon,
        serviceAlt: 'Gen AI and ML',
        serviceClass: 'very-light-pink-three',
        serviceLink: '/services/gen-ai-ml-development',
        linkType: EXTERNAL_LINK,
      },
      {
        id: '2',
        serviceHeading: 'Migration and Modernization',
        serviceParagraph:
          'Make your application future ready by improving the tech stack, code, and cloud infrastructure.',
        serviceIcon: images.cloudMigrationMicon,
        serviceAlt: 'Migration and Modernization',
        serviceClass: 'pale-lavender',
        serviceLink: '/services/aws-cloud-migration/',
        linkType: EXTERNAL_LINK,
      },
      {
        id: '3',
        serviceHeading: 'SaaS Migration and Engineering',
        serviceParagraph:
          'Develop, migrate, and optimize SaaS applications through cloud-native solutions',
        serviceIcon: images.awsSaasIcon,
        serviceAlt: 'SaaS Migration and Engineering',
        serviceClass: 'very-light-pink-three',
        serviceLink: '/services/aws-saas-development/',
        linkType: EXTERNAL_LINK,
      },
      {
        id: '4',
        serviceHeading: 'Data Science and Engineering',
        serviceParagraph:
          'Unify data silos, accelerate analytics, and enable real-time decision-making to reach your strategic goals',
        serviceIcon: images.biDataEngineeringMicon,
        serviceAlt: 'Data Science and Engineering',
        serviceClass: 'light-green',
        serviceLink: '/services/aws-data-engineering/',
        linkType: EXTERNAL_LINK,
      },
      {
        id: '5',
        serviceHeading: 'CTO as a Service',
        serviceParagraph:
          'Get leadership guidance to implement a technical roadmap to reach your business goals.',
        serviceIcon: images.ctoAsAServiceIcon,
        serviceAlt: 'CTO as a Service',
        serviceClass: 'sky-blue',
        serviceLink: '/services/cto-as-service/',
        linkType: EXTERNAL_LINK,
      },
      {
        id: '6',
        serviceHeading: 'Cloud Management',
        serviceParagraph:
          'Enhance cloud operational efficiency, automation, and visibility while we manage your infrastructure and resources',
        serviceIcon: images.cloudArchDesignMicon,
        serviceAlt: 'Cloud Management',
        serviceClass: 'ice-blue',
        serviceLink: '/services/aws-cloud-management/',
      },

      {
        id: '8',
        serviceHeading: 'Quality Engineering',
        serviceParagraph:
          'Confidently deliver software faster with a talented team of testers and robust QA processes',
        serviceIcon: images.testAutomationMicon,
        serviceAlt: 'Quality Engineering',
        serviceClass: 'very-light-pink',
        serviceLink: '/services/software-testing/',
      },
    ],
  },
  BrandsLogoData: {
    title: (
      <>
        Trusted by the world’s leading{' '}
        <span className="highlight-head">brands</span>{' '}
      </>
    ),
    clientLogos: [
      {
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogosrcg: images.bancoSantanderLogo,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogosrcg: images.larvolLogo,
        clientLogoAlt: 'larvol',
      },
      {
        clientLogosrcg: images.solvpathLogo,
        clientLogoAlt: 'Solvpath',
      },
      {
        clientLogosrcg: images.experianLogo,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogosrcg: images.natwestLogo,
        clientLogoAlt: 'Natwest',
      },
      {
        clientLogosrcg: images.ciscoLogo,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrcg: images.cameoLogoSvg,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fidelityLogo,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogosrcg: images.hsbcLogo,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogosrcg: images.nextBigIdea,
        clientLogoAlt: 'NextBigIdea',
      },
      {
        clientLogosrcg: images.jpLogo,
        clientLogoAlt: 'JPMorgan',
      },
      {
        clientLogosrcg: images.bankOfAmericaLogo,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrcg: images.newsUkLogo,
        clientLogoAlt: 'NewsUk',
      },
      {
        clientLogosrcg: images.apaLogo,
        clientLogoAlt: 'APA',
      },
    ],
  },
  OutsourcingData: {
    title: <>Beyond POCs - Build Gen AI enterprises at scale</>,
    outsourcingList: [
      {
        iconUrl: images.gaiMicon,
        cardTitle: 'Accelerate Data Readiness for Gen AI',
        cardInfo:
          'We provide end-to-end data strategy solutions, initiating clients on their Gen AI journey. Our offerings include automated data segmentation and integration solutions. We monitor, validate, and enhance data quality for data science applications, utilizing ML and MLOps. Additionally, we employ AI and ML-driven synthetic data generation and automate LLM lifecycle management.',
      },
      {
        iconUrl: images.organizationReadinessIcon,
        cardTitle: 'Organizational Readiness',
        cardInfo:
          'We develop modernization roadmaps based on Gen AI opportunities within your enterprise value chain. Our expertise extends to building and training high-output tech teams proficient in creating and utilizing Gen AI solutions. Additionally, we assist in managing, maintaining, and scaling AI practice teams to ensure future growth, enabling you to surpass your competitors.',
      },
      {
        iconUrl: images.aiCenterExcellenceIcon,
        cardTitle: 'AI Center of Excellence',
        cardInfo:
          'We accelerate pod deployment using our flexible engagement model with Gen AI talent management. We implement Gen AI driven product features through product engineering. We provide data-driven user insights using NLP, facilitating rapid product development and iterations. Additionally, we help accelerate your path to Gen AI ROI by managing LLM costs through optimization services.',
      },
    ],
  },
  OrganizationsData: {
    title: (
      <>
        Why Organizations <span className="highlight-head">Choose Simform</span>{' '}
      </>
    ),
    info:
      'Discover the many ways in which our clients have embraced the benefits of the Simform way of engineering.',
    CaseStudyList: [
      {
        caseLogo2x: images.csAmericanPsychologicalAssociationLogo,
        title: 'Next-Gen research: ChatGPT powered cognitive search solution',
        info:
          'Gen AI solutions for APA researchers with fast and efficient retrieval of data leveraging LLMs deployed on AWS Bedrock.',
        caseLink: '/case-studies/advanced-gen-ai-platform-for-faster-research/',
        linkType: EXTERNAL_LINK,
        resultInfo: [
          {
            resultTitle: (
              <>
                20X improvement in <br />
              </>
            ),
            info: 'search speed',
          },
          {
            resultTitle: 'Unique multi-turn conversations',
            info: 'and maintains context across discussions',
          },
        ],
      },
      {
        caseLogo2x: images.csMindfulmeLogo,
        title: 'Advancing student mental health with AI-Driven therapy bot',
        info:
          'Advanced chatbot powered by OpenAI GPT-3.5T, AWS Bedrock and LangChain framework acts as an AI therapist to solve students’ mental health issues.',
        caseLink: '/case-studies/ai-driven-mental-health-support-application/',
        linkType: EXTERNAL_LINK,
        className: 'light-peach-bg',
        resultInfo: [
          {
            resultTitle: (
              <>
                80% reduction in <br />
              </>
            ),
            info: 'support access time',
          },
          {
            resultTitle: (
              <>
                50 universities <br />
              </>
            ),
            info: 'implemented within the first year',
          },
        ],
      },
      {
        caseLogo2x: images.csLarvolLogo,
        title:
          'Intelligent QnA conversational interface for pharmaceutical conferences',
        info:
          'Natural, informative, and fast data retrieval using Gen AI models for efficient information access for conference attendees.',
        caseLink:
          '/case-studies/intelligent-qna-interface-to-enhance-pharma-conferences/',
        linkType: EXTERNAL_LINK,
        className: 'light-blue-grey-bg',
        resultInfo: [
          {
            resultTitle: (
              <>
                10X improvement in <br />
              </>
            ),
            info: 'data retrieval',
          },
          {
            resultTitle: (
              <>
                95% accuracy in <br />
              </>
            ),
            info: 'user query understanding',
          },
        ],
      },
      {
        caseLogo2x: images.csSolvpathLogo,
        title:
          'AI-Powered Chatbot for Seamless and Fast Customer Service Support',
        info:
          'Chatbot redefines the customer experience by providing real-time, accurate, and scalable responses.',
        caseLink: '/case-studies/ai-powered-chatbot-for-ecommerce/',
        linkType: EXTERNAL_LINK,
        className: 'pale-peach-bg',
        resultInfo: [
          {
            resultTitle: (
              <>
                3X increase in <br />
              </>
            ),
            info: 'customer satisfaction',
          },
          {
            resultTitle: (
              <>
                Quick user feedback leads <br />
              </>
            ),
            info: 'to continuous product improvement',
          },
        ],
      },
      {
        caseLogo2x: images.visiblyCaseLogo2x,
        title: 'Software that digitize the eye test within five minutes',
        info:
          '…a digital platform which could be an alternative to the traditional lab coat and lens approach for diagnosis…',
        caseLink: '/case-studies/visibly/',
        linkType: EXTERNAL_LINK,
        resultInfo: [
          {
            resultTitle: (
              <>
                Reduced eye test
                <br />
              </>
            ),
            info: 'cost by 75%',
          },
          {
            resultTitle: (
              <>
                Complete eye test
                <br />
              </>
            ),
            info: 'using smartphone within 10-15 minutes',
          },
        ],
      },
      {
        caseLogo2x: images.fihCaseLogo2x,
        title:
          'Improving the online web experience for International Hockey Federation (FIH)',
        info:
          '…they wanted increased user engagement, usability, discoverability, while providing…',
        caseLink: '/case-studies/fih/',
        linkType: EXTERNAL_LINK,
        className: 'light-peach-bg',
        resultInfo: [
          {
            resultTitle: (
              <>
                Completed website
                <br />
              </>
            ),
            info: 'within 3 months before World Cup',
          },
          {
            resultTitle: (
              <>
                Scalable website
                <br />
              </>
            ),
            info: 'to handle millions of visitors',
          },
        ],
      },
      {
        caseLogo2x: images.freewireCaseLogo2x,
        title:
          'EV charging platform to revolutionize the way we power everything',
        info:
          'FreeWire‘s Mobi Gen product line offers clean and quiet on-site power to substitute…',
        caseLink: '/case-studies/freewire-scalable-vehicle-charging/',
        linkType: EXTERNAL_LINK,
        className: 'light-blue-grey-bg',
        resultInfo: [
          {
            resultTitle: (
              <>
                $125 Million
                <br />
              </>
            ),
            info: 'Funding for Next Gen development',
          },
          {
            resultTitle: (
              <>
                Used by big companies
                <br />
              </>
            ),
            info: 'Walmart, Uber, Volvo, Linkedin, BellEnergy',
          },
        ],
      },
      {
        caseLogo2x: images.craypayCaseLogo2x,
        title: 'M-payment solution to modernise retail buying experience',
        info:
          '…over 70% of consumers say that if they get benefits of loyalty points or perks in retail stores, they are ready…',
        caseLink: '/case-studies/cray-pay/',
        linkType: EXTERNAL_LINK,
        className: 'pale-peach-bg',
        resultInfo: [
          {
            resultTitle: (
              <>
                10,000+ users
                <br />
              </>
            ),
            info: '60,000+ retail locations in USA',
          },
          {
            resultTitle: (
              <>
                Cryptocurrency
                <br />
              </>
            ),
            info: 'was made spendable in retail store',
          },
        ],
      },
      {
        caseLogo2x: images.transTmsCsLogo2x,
        title: 'Simplifying the logistics industry of North America',
        info: (
          <>
            …An end-to-end freight brokerage system that automates day-to-day
            functions of today's logistics professionals...
          </>
        ),
        caseLink: '/case-studies/trans-tms/',
        linkType: EXTERNAL_LINK,
        resultInfo: [
          {
            resultTitle: (
              <>
                25% Cut down
                <br />
              </>
            ),
            info: 'cost of manual operations.',
          },
          {
            resultTitle: (
              <>
                40% Reduction
                <br />
              </>
            ),
            info: 'in customer-serving time.',
          },
        ],
      },
      {
        caseLogo2x: images.missionRabiesCsLogo2x,
        title: 'mHealth and precision analytics for a WHO-backed NGO',
        info:
          '…app must empower users with active functionalities, with or without the internet, alongside offline-online synchronization with data integrity…',
        caseLink: '/case-studies/mission-rabies/',
        linkType: EXTERNAL_LINK,
        className: 'light-peach-bg',
        resultInfo: [
          {
            resultTitle: (
              <>
                2x improvement
                <br />
              </>
            ),
            info: 'in project management',
          },
          {
            resultTitle: (
              <>
                1 Million+ dogs
                <br />
              </>
            ),
            info: 'vaccinated.',
          },
        ],
      },
      {
        caseLogo2x: images.stubbsWoottonCsLogo2x,
        title: 'Helping luxury shoe brand go digital',
        info: (
          <>
            …take customized orders by applying various combinations of fabrics,
            motifs, and monograms based on the customer's preferences…
          </>
        ),
        caseLink: '/case-studies/stubbs-woottons/',
        linkType: EXTERNAL_LINK,
        className: 'light-blue-grey-bg',
        resultInfo: [
          {
            resultTitle: (
              <>
                30% Increase in
                <br />
              </>
            ),
            info: 'customer engagement.',
          },
          {
            resultTitle: (
              <>
                2x Uplift in sales
                <br />
              </>
            ),
            info: 'within 6 months after app launch.',
          },
        ],
      },
      {
        caseLogo2x: images.redbullCsLogo2x,
        title: 'Kiosk based Photo Booth solution',
        info:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseLink: '/case-studies/redbull-photobooth/',
        linkType: EXTERNAL_LINK,
        className: 'pale-peach-bg',
        resultInfo: [
          {
            resultTitle: (
              <>
                Seamless handling
                <br />
              </>
            ),
            info: 'of 10,000+ users per event',
          },
          {
            resultTitle: (
              <>
                15% Increase in
                <br />
              </>
            ),
            info: 'social media engagement.',
          },
        ],
      },
      {
        caseLogo2x: images.trygCsLogo2x,
        title: (
          <>Built a telemetric IoT platform for Scandinavia's general insurer</>
        ),
        info:
          '…fleet tracking solution for fleet operators and drivers providing insights into vehicle and driving behaviors…',
        caseLink: '/case-studies/tryg/',
        linkType: EXTERNAL_LINK,
        resultInfo: [
          {
            resultTitle: (
              <>
                100% Improvement
                <br />
              </>
            ),
            info: 'in handling gigabytes of data',
          },
          {
            resultTitle: (
              <>
                50% Reduction
                <br />
              </>
            ),
            info: 'in operational costs.',
          },
        ],
      },
      {
        moreCaseStudiesCard: true,
        caseLink: '/case-studies/',
        linkType: EXTERNAL_LINK,
        logoItems: [
          {
            caseLogo2x: images.sabreCsLogo2x,
          },
          {
            caseLogo2x: images.newtonCsLogo2x,
          },
          {
            caseLogo2x: images.renessasCsLogo2x,
          },
          {
            caseLogo2x: images.cameoLogo2x,
          },
          {
            caseLogo2x: images.accentLogo2x,
          },
        ],
      },
    ],
  },
  CultureCraftedData: {
    title: (
      <>
        Culture crafted and loved by{' '}
        <span className="highlight-head">engineers</span>{' '}
      </>
    ),
    info: (
      <>
        The Simform culture is crafted to create a truly rewarding experience
        for engineers,{' '}
        <strong>
          meaning your team will be made up of highly motivated people.
        </strong>{' '}
        As a result our teams are tightly integrated with yours. And that is
        shown by the love they shower back on us.
      </>
    ),
    infoLink: '/careers/',
    gretplaceToWork: {
      title: 'Great Place to Work reviews',
      link:
        'https://www.greatplacetowork.in/great/company/simform-solutions-pvt-ltd',
      reviewList: [
        {
          label: 'I feel a sense of pride working at Simform.',
          designation: 'DevOps Engineer',
        },
        {
          label: 'Simform has accelerated by career graph.',
          designation: 'Tech Lead',
        },
        {
          label: 'Simform = fairness, openness and equality.',
          designation: 'QA Lead',
        },
      ],
    },
    glassdoor: {
      title: 'Glassdoor reviews',
      link: 'https://www.glassdoor.co.in/Reviews/Simform-Reviews-E777484.htm',
      reviewList: [
        {
          label: 'Culture that drives innovation in everything we do.',
          designation: 'Sr. Designer',
        },
        {
          label: 'Great benefits, great people.',
          designation: 'Full-stack Developer',
        },
        {
          label: 'Fun and mentally healthy workplace.',
          designation: 'Software Developer',
        },
      ],
    },
  },
  AwardsData: {
    title: (
      <>
        Recent <span className="highlight-head">Awards and Certifications</span>{' '}
      </>
    ),
  },
  OurperspectiveData: {
    title: (
      <>
        Our Perspective on{' '}
        <span className="highlight-head">Gen AI and Product Engineering</span>
      </>
    ),
    OurPerspectiveContent: [
      {
        linkType: EXTERNAL_LINK,
        cardLink: '/blog/why-work-with-aws-premier-partner/',
        cardTitle:
          'Working with an AWS Premier Tier Services Partner: What’s in it for You?',
        cardDetails:
          'Learn how AWS Premier Partners provide specialized skills to build future-ready architectures, securing vital data and apps while optimizing costs.',
        blogerName: 'By Hiren Dhaduk',
        postDate: 'March 14, 2024',
      },
      {
        linkType: EXTERNAL_LINK,
        cardLink: '/blog/the-state-of-generative-ai/',
        cardTitle:
          'State of Generative AI in 2024 | Insightful Survey Findings',
        cardDetails:
          'Explore expert insights on the adoption, use cases, significant challenges, and practical solutions for seamlessly integrating generative AI in organizations.',
        blogerName: 'By Hiren Dhaduk',
        postDate: 'January 8, 2024',
      },
      {
        linkType: EXTERNAL_LINK,
        cardLink: '/blog/cost-optimization-strategies-aws-migration/',
        cardTitle:
          '7 Effective Strategies for Optimizing Costs in Your AWS Migration',
        cardDetails:
          'Explore the cost optimization strategies and best practices to maximize your savings while getting the most out of your investment during AWS migration.',
        blogerName: 'By Hiren Dhaduk',
        postDate: 'February 23, 2024',
      },
      {
        linkType: EXTERNAL_LINK,
        cardLink: '/battle-tested-scalable-software-architecture/',
        cardTitle:
          'Building Battle-tested Software Architecture: Lessons from Top Tech Companies',
        cardDetails:
          'A one-stop guide that provides comprehensive and expert advice to CTOs & tech decision-makers for evolving software architecture at scale.',
        cardLinkText: 'Download',
        HorizontalCard: true,
      },
      {
        linkType: EXTERNAL_LINK,
        openNewTab: true,
        cardLink: '/build-enterprise-kubernetes-strategy/',
        cardTitle: 'CTOs Handbook to Build an Enterprise Kubernetes Strategy',
        cardDetails:
          'Adoption of new technologies is advantageous yet challenging. This eBook is for each & tech-savvy enterprise that wants to embrace Kubernetes to manage containers, virtual machines, and other modern applications.',
        cardLinkText: 'Download',
        HorizontalCard: true,
      },
      {
        linkType: EXTERNAL_LINK,
        openNewTab: true,
        cardLink: '/cto-handbook-building-scalable-frontend/',
        cardTitle: 'CTO’s Handbook for Building Scalable Frontend',
        cardDetails:
          'In a typical startup scenario, you have a great business idea that gets validated in the market through MVP. After that, the number of users starts to grow exponentially, and many startups can’t scale at such a rapid pace.',
        cardLinkText: 'Download',
        HorizontalCard: true,
      },
      {
        linkType: EXTERNAL_LINK,
        openNewTab: true,
        cardLink: '/20-ways-microservices-are-transforming-industries/',
        cardTitle:
          '20+ Ways Microservices are Transforming Industries: Use Cases from Capital One, Facebook, Uber, and more',
        cardDetails:
          'The business world has realized the significance of adopting a microservices architecture and how it can transform the development processes.',
        cardLinkText: 'Download',
        HorizontalCard: true,
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/blog/building-scalable-application-aws-platform/',
        featurecardTitle:
          'How to Build a Scalable Application up to 1 Million Users on AWS',
      },
      {
        blogLink:
          'https://www.simform.com/blog/custom-software-development-costs/',
        featurecardTitle:
          'How to Estimate Custom Software Development Costs for your Projects?',
      },
      {
        blogLink:
          'https://www.simform.com/blog/custom-software-vs-off-the-shelf/',
        featurecardTitle:
          'Custom Software vs Off-the-shelf: Best Approach for Business Growth',
      },
    ],
    CTACard: {
      title: <>Hire the best developers and designers around!</>,
      buttonName: 'Hire Top Developers',
      buttonLink: '/contact/',
    },
  },
}
