import React from 'react'
import Button from 'components/Button/Button'
import Image from 'components/ImgOptimized'
import { OutsourcingPartnerStyle } from './OutsourcingPartnerSec.styles'
import { Link } from 'gatsby'
import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'

function OutsourcingCard(props) {
  return (
    <div className="outsourcing-card">
      <div className="outsourcing-card-head">
        <img
          className="outsourcing-icon"
          src={props.iconUrl}
          alt={props.cardTitle}
        />
        <h5>{props.cardTitle}</h5>
      </div>
      <div className="outsourcing-card-info">
        <p>{props.cardInfo}</p>
      </div>
    </div>
  )
}

function OutsourcingPartnerSec(props) {
  const { OutsourcingProps } = props
  return (
    <OutsourcingPartnerStyle>
      <div className="container">
        <div className="outsourcing-head">
          <h2>{OutsourcingProps.title}</h2>
          {OutsourcingProps.info && <p>{OutsourcingProps.info}</p>}
        </div>
        <div className="outsourcing-content">
          {OutsourcingProps.outsourcingList.map((outsourcing, i) => {
            return (
              <React.Fragment key={i}>
                <div className="outsourcing-card-link">
                  <OutsourcingCard
                    iconUrl={outsourcing.iconUrl}
                    cardTitle={outsourcing.cardTitle}
                    cardInfo={outsourcing.cardInfo}
                  />
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </OutsourcingPartnerStyle>
  )
}

export default OutsourcingPartnerSec
