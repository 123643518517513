import React from 'react'
import { AwardscertificationssecStyles } from './Awardscertificationssec.styles'
import Image from 'components/ImgOptimized'
function Awardscertificationssec(props) {
  const { AwardsProps } = props
  return (
    <AwardscertificationssecStyles>
      <div className="container">
        <div className="award-sec-head">
          <h2>{AwardsProps.title}</h2>
        </div>
        <div className="topLogos">
          {props.topLogo.map((logo, i) => {
            return (
              <div key={i} className="topLogosCol">
                <Image
                  fluid={logo}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 218,
                  }}
                />
              </div>
            )
          })}
        </div>
        <div className="bottomLogos">
          {props.bottomLogos.map((logo, i) => {
            return (
              <div key={i} className="bottomLogosCol">
                <div className="logoCard">
                  <Image
                    fluid={logo}
                    lazyload={{
                      enabled: true,
                      once: true,
                      offset: 1000,
                      heigth: 218,
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </AwardscertificationssecStyles>
  )
}

export default Awardscertificationssec
