import React, { memo } from 'react'
import SingleService from 'components/SingleService/SingleService'
import { HomeServicestyle } from './HomeService.styles'
import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'
import { useSlider } from 'components/hooks/useSlider'
import { Link } from 'gatsby'

const HomeService = memo(({ homeServiceData: { homeServiceProps } }) => {
  let selector = 'home-service-list-wrap'
  let wrapper = 'home-service-list'
  let scroll = 'indicator-container'
  let scrollIndecator = `indicator-inner`

  useSlider({
    selector: selector,
    wrapper,
    scroll,
    scrollIndecator,
    speed: 5.5,
  })

  let sample = (
    <div>
      <div className={selector}>
        <div className={wrapper}>
          {homeServiceProps.serviceContents.map((serviceContent, i) => {
            return (
              <div key={i} className="single-service-wrap">
                <>
                  {serviceContent.linkType !== EXTERNAL_LINK ? (
                    <Link
                      to={serviceContent.serviceLink}
                      className="single-service-card-link"
                    >
                      <SingleService {...serviceContent} serviceLink={false} />
                    </Link>
                  ) : (
                    <a
                      href={`${isWindowDefined ? window.location.origin : ''}${
                        serviceContent.serviceLink
                      }`}
                      className="single-service-card-link"
                    >
                      <SingleService {...serviceContent} serviceLink={false} />
                    </a>
                  )}
                </>
              </div>
            )
          })}
        </div>
      </div>
      <div className="indicator-container">
        <div className="indicator-inner"></div>
      </div>
    </div>
  )

  return (
    <HomeServicestyle className="home-service">
      <div className="container">
        <div className="heading-wrapper">
          <h3>{homeServiceProps.homeServiceHeading}</h3>
        </div>
      </div>
      {/*<RSCExample
        selector={selector}
        wrapper={wrapper}
        homeServiceProps={homeServiceProps}
      />*/}

      {sample}
    </HomeServicestyle>
  )
})

export default HomeService
