import { images } from 'config/images'
import {
  black,
  bodyFont,
  headingFont,
  lg,
  primaryColor,
  sm,
  themeColor,
  white,
  xl,
} from 'config/variables'
import styled from 'styled-components'

export const NewcasestudiescardStyles = styled.div`
  padding: 20px;
  background-color: #b1e2bf;
  width: 100%;
  border-radius: 12px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  min-height: 470px;
  display: flex;
  flex-direction: column;
  ${xl(`
    padding: 20px;
  `)}
  ${lg(`
    padding: 15px;
  `)}
  &.pale-peach-bg {
    background-color: #eadab8;
  }
  &.light-blue-grey-bg {
    background-color: #b4c2e9;
  }
  &.light-peach-bg {
    background-color: #ecccb7;
  }
  &:hover {
    box-shadow: 4px 4px 26px 5px rgb(0 0 0 / 5%), 6px 8px 26px 0 rgb(0 0 0 / 2%);
    transform: translate(0, -10px);
    .case-info {
      .btn-read {
        color: ${themeColor};
        .line {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
  .logo {
    margin-bottom: 18px;
    img {
      max-height: 30px;
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }
  .case-info {
    h3 {
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
      margin: 0 0 10px;
      ${xl(`
       font-size: 20px;
       line-height: 26px;
    `)}
      ${lg(`
       font-size: 20px;
       line-height: 26px;
    `)}
    }
    p {
      font-size: 17px;
      font-weight: 400;
      margin: 0 0 30px;
      line-height: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      ${xl(`
       font-size: 16px;
       line-height: 20px;
       margin: 0 0 20px;
    `)}
      ${lg(`
       font-size: 16px;
       line-height: 20px;
       margin: 0 0 20px;
    `)}
    }
    .btn-read {
      font-size: 18px;
      line-height: 18px;
      margin: 20px 0 0 0;
      font-weight: 500;
      position: relative;
      color: ${primaryColor};
      transition: all 0.5s ease;
      display: inline-block;
      overflow: visible;
      .line {
        width: 60px;
        height: 3px;
        background: ${primaryColor};
        border-radius: 1px;
        display: block;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        position: absolute;
        top: -20px;
        &::before {
          content: '';
          height: 100%;
          background: ${themeColor};
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          -webkit-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }
      }
    }
  }
  .case-result-info {
    margin-top: auto;
    h4 {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      padding: 0 0 12px;
      margin: 0 0 11px;
      font-family: ${bodyFont};
      border-bottom: 1px solid ${primaryColor};
    }
    .case-result-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      .case-result-col {
        padding: 0 10px;
        flex: 0 0 50%;
        max-width: 50%;
        width: 50%;
        h5 {
          font-size: 17px;
          line-height: 22px;
          font-weight: 500;
          font-family: ${bodyFont};
          margin: 0 0 7px;
          letter-spacing: -0.5px;
          ${xl(`
          font-size: 16px;
          line-height: 20px; 
        `)}
          ${lg(`
          font-size: 16px;
          line-height: 20px; 
        `)}
        span {
            font-weight: 400;
            margin: 0;
          }
        }
      }
    }
  }
`
export const MoreCaseStudiesCardStyle = styled.div`
  width: 100%;
  border-radius: 12px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  min-height: 470px;
  display: flex;
  overflow: hidden;
  background: url(${images.moreCaseStudiesCardBg}) no-repeat center;
  background-size: cover;
  ${sm(`
       flex-direction: column;
  `)}
  &:hover {
    box-shadow: 4px 4px 26px 5px rgb(0 0 0 / 5%), 6px 8px 26px 0 rgb(0 0 0 / 2%);
    transform: translate(0, -10px);
    .case-info {
      .btn-read {
        color: ${themeColor};
        .line {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
  .left-logo-col {
    width: 259px;
    flex: 0 0 259px;
    max-width: 259px;
    background: rgba(0, 0, 0, 0.74);
    padding: 10px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    ${sm(`
      width: 100%;
      flex: 1 1 auto;
      max-width: 100%;
    `)}
    .logo-item {
      padding: 10px 20px;
      ${sm(`
        padding: 8px 20px;
        max-width: 210px;
     `)}
      img {
        max-height: 40px;
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }
  }
  .text-right-col {
    flex: 1 1 auto;
    padding: 30px 40px 30px 10px;
    ${sm(`
       padding: 10px;
       display: flex;
       align-items: center;
       justify-content: center;
       background: rgba(0, 0, 0, 0.74);
    `)}
    p {
      color: ${white};
      font-family: ${headingFont};
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0px;
      text-align: right;
      margin: 0;
      ${sm(`
        text-align: center;
      `)}
    }
  }
`
