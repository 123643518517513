import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { DashboardProps } from 'components/Props/dashboard'
import { md } from 'config/variables'
import HomeService from 'components/HomeService/HomeService'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { ErrorBoundary } from 'components/ErrorBoundary'
import HomeBanner from 'components/HomeBanner/HomeBanner'
import BrandsLogoSec from 'components/BrandsLogoSec/BrandsLogoSec'
import OutsourcingPartnerSec from 'components/OutsourcingPartnerSec/OutsourcingPartnerSec'
import Organizationssec from 'components/Organizationssec/Organizationssec'
import Culturecraftedsec from 'components/Culturecraftedsec/Culturecraftedsec'
import Awardscertificationssec from 'components/Awardscertificationssec/Awardscertificationssec'
import Ourperspectivesec from 'components/Ourperspectivesec/Ourperspectivesec'

const IndexPage = props => {
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  let topAwardsLogo = [
    props.data.iaopLogo.childImageSharp.fluid,
    props.data.awsPartnerLogo.childImageSharp.fluid,
    props.data.certinspectRLogo.childImageSharp.fluid,
    props.data.glassdoorLogo.childImageSharp.fluid,
    // props.data.microsoftTrustedCertified.childImageSharp.fluid,
    props.data.cmmiLogo.childImageSharp.fluid,
  ]
  let bottomAwardsLogos = [
    props.data.awsCloudPractitioner.childImageSharp.fluid,
    props.data.awsDevopsEngineer.childImageSharp.fluid,
    props.data.awsSolutionArchitect.childImageSharp.fluid,
    props.data.awsConsultingPartner.childImageSharp.fluid,
  ]
  let imageItems = [
    props.data.blogCardOne.childImageSharp.fluid,
    props.data.blogCardTwo.childImageSharp.fluid,
    props.data.blogCardThree.childImageSharp.fluid,
    props.data.ebookCard.childImageSharp.fluid,
    props.data.brochureCard.childImageSharp.fluid,
    props.data.ebookCardTwo.childImageSharp.fluid,
    props.data.ebookCardThree.childImageSharp.fluid,
  ]
  return (
    <ErrorBoundary>
      <Layout mainClass="home" location={location}>
        <SEO
          title="AWS Premier Partner | Gen AI | Product Engineering"
          description="Simform is a digital product engineering company with world-class agile teams to scale your business."
          keywords={[`Simform`]}
          links={[
            {
              href: 'https://www.simform.com/',
              rel: 'canonical',
            },
          ]}
          meta={[
            {
              property: 'og:locale',
              content: 'en_US',
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:site_name',
              content: 'Simform',
            },
            {
              property: 'og:url',
              content: 'https://www.simform.com',
            },
            {
              itemProp: 'name',
              content: 'Simform',
            },
            {
              property: 'og:image',
              content: 'https://www.simform.com/banner.png',
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image',
            },
            {
              name: 'twitter:site',
              content: '@Simform',
            },
            {
              name: 'twitter:creator',
              content: '@Simform',
            },
            {
              name: 'twitter:image',
              content: 'https://www.simform.com/banner.png',
            },
          ]}
        />
        <div
          css={`
            .stats-section {
              ${md(`
              display: none;
            `)}
            }
          `}
        >
          <HomeBanner
            bannerData={DashboardProps}
            showBannerImage={true}
            {...props}
          />
          <div className="lazy-load-div">
            <HomeService homeServiceData={DashboardProps} />
            <BrandsLogoSec BrandsLogoData={DashboardProps.BrandsLogoData} />
          </div>
          {showContent && (
            <>
              <OutsourcingPartnerSec
                OutsourcingProps={DashboardProps.OutsourcingData}
              />
              <Organizationssec
                OrganizationsProps={DashboardProps.OrganizationsData}
              />
              <Culturecraftedsec
                CultureCraftedProps={DashboardProps.CultureCraftedData}
              />
              <Awardscertificationssec
                AwardsProps={DashboardProps.AwardsData}
                topLogo={topAwardsLogo}
                bottomLogos={bottomAwardsLogos}
              />
              <Ourperspectivesec
                OurperspectiveProps={DashboardProps.OurperspectiveData}
                ImageThumb={imageItems}
              />
            </>
          )}
        </div>
      </Layout>
    </ErrorBoundary>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/home-hero-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 520) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    iaopLogo: file(relativePath: { regex: "/iaop-logo@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    awsPartnerLogo: file(relativePath: { regex: "/aws-new-badge.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 250) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    certinspectRLogo: file(
      relativePath: { regex: "/certinspect-r-logo@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 116) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    glassdoorLogo: file(relativePath: { regex: "/glassdoor-logo@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    microsoftTrustedCertified: file(
      relativePath: { regex: "/microsoft-trusted-certified-logo.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 190) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    cmmiLogo: file(relativePath: { regex: "/cmmi-level3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    awsSolutionArchitect: file(
      relativePath: { regex: "/aws-solution-architect@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 125) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    awsCloudPractitioner: file(
      relativePath: { regex: "/aws-hm-cloud-practitioner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 125) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    awsDevopsEngineer: file(
      relativePath: { regex: "/aws-hm-devops-engineer@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 125) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    awsConsultingPartner: file(
      relativePath: { regex: "/aws-consulting-partner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 125) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ebookCard: file(
      relativePath: {
        regex: "/ebook-building-battle-tested-software-architecture@2x.png/"
      }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    brochureCard: file(
      relativePath: { regex: "/ebook-enterprise_kubernative_strategy@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ebookCardTwo: file(
      relativePath: { regex: "/ebook-building-scalable-frontend@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ebookCardThree: file(
      relativePath: { regex: "/ebook-microservices-are-transforming@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    blogCardOne: file(relativePath: { regex: "/aws-premier-blog@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    blogCardTwo: file(
      relativePath: { regex: "/state-of-generative-ai-in-2024@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    blogCardThree: file(
      relativePath: { regex: "/aws-migration-blog@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
