import { lg, md } from 'config/variables'
import styled from 'styled-components'

export const AwardscertificationssecStyles = styled.section`
  .award-sec-head {
    margin-bottom: 40px;
    ${md(`
    margin-bottom: 20px; 
  `)}
    h2 {
      margin-bottom: 0;
    }
  }
  .topLogos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    ${lg(`
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center; 
  `)}
    .topLogosCol {
      min-width: 100px;
      &:nth-child(1) {
        min-width: 200px;
      }
      &:nth-child(2) {
        min-width: 250px;
      }
      &:nth-child(3) {
        min-width: 110px;
      }
      &:nth-child(4) {
        min-width: 150px;
      }
    }
  }
  .bottomLogos {
    display: flex;
    margin: 0 -15px;
    ${md(`
    flex-wrap: wrap;
    margin: 0 -10px;
  `)}
    .bottomLogosCol {
      flex: 1;
      padding: 0 15px;
      ${md(`
      width:50%;
      max-width:50%;
      flex:0 0 50%;
      padding: 0 10px;
      margin-bottom: 10px; 
    `)}
      &:nth-child(2) {
        .logoCard {
          border-color: #e7ccfd;
        }
      }
      &:nth-child(3) {
        .logoCard {
          border-color: #ffe096;
        }
      }
      &:last-child {
        .logoCard {
          border-color: #b3d7d4;
        }
      }
      .logoCard {
        width: 100%;
        padding: 15px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: solid 1px #bee1ff;
        .gatsby-image-wrapper {
          width: 100%;
        }
      }
    }
  }
`
